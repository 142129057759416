<template>
    <div class="w-full max-w-[800px] m-auto flex items-center justify-center z-40 relative pointer-events-auto">
        <div
            class="flex h-full w-full bg-white flex-col overflow-hidden border-none rounded-xl pt-3 pb-0 shadow-[0px_12px_24px_rgba(0,0,0,0.08),0_0_0_1px_rgba(0,0,0,0.05),0_1px_2px_0_rgba(0,0,0,0.05),0_145px_72px_0px_rgba(0,0,0,0.01),0_80px_60px_0_rgba(0,0,0,0.03)]">
            <div class="flex items-center rounded-md bg-gray-100 mx-3 mb-3">
                <div class="flex w-[calc(100%-80px)] items-center px-3" @click="focusInput">
                    <input type="text" autocomplete="off" autocorrect="off" spellcheck="false" id="searchQuery"
                        class="flex h-11 w-full text-base rounded-md bg-transparent border-none px-0 py-3 outline-none focus:ring-0 placeholder:text-black/30 disabled:cursor-not-allowed disabled:opacity-50"
                        placeholder="Search for statistical sources, your documents, ..." v-model="query"
                        @input="onQueryInput" @keyup.enter="handleSearchEnter" />
                    <span id="hiddenQuery"
                        class="absolute flex h-11 w-auto text-base border-none px-0 outline-none invisible whitespace-nowrap">{{
                            query }}</span>
                    <span class="text-gray-400 pl-1 whitespace-nowrap select-none truncate min-w-28"
                        v-if="query.length > 0 && autoCompleteString.length > 0">{{ autoCompleteString }}</span>
                    <UIcon v-if="loading" name="i-heroicons-arrow-path"
                        class="absolute right-8 animate-spin h-5 w-5 text-gray-400" />
                </div>
                <div class="ml-auto flex items-center justify-center gap-3 px-2 w-[80px]" v-if="!loading">
                    <UTooltip text="Saved Prompts">
                        <button class="flex items-center justify-center w-full bg-gray-100"
                            @click="showSavedPromptsDialog = true">
                            <Icon name="heroicons:bookmark" class="h-5 w-5 text-gray-400 hover:text-gray-500"
                                aria-hidden="true" />
                        </button>
                    </UTooltip>

                    <SelectsToggleSelect v-model="providersSelected" :items="filteredProviders" placeholder="Providers"
                        searchable searchablePlaceholder="Search providers" optionGroupTitle="Providers" itemText="name"
                        itemValue="id" :showSelectAll="false" class="flex items-center justify-center">
                        <UTooltip text="Filter statistical source providers">
                            <button class="flex items-center justify-center w-full bg-gray-100"
                                id="provider-filter-button">
                                <Icon name="heroicons:funnel" class="h-5 w-5 text-gray-400 hover:text-gray-500"
                                    aria-hidden="true" />
                                <span v-if="providersSelected.length > 0"
                                    class="absolute -top-0.5 -right-1 text-[9px] rounded-full bg-wobby-purple-500 text-white px-1">{{
                                        providersSelected.length }}</span>
                            </button>
                        </UTooltip>
                    </SelectsToggleSelect>
                </div>
            </div>
            <div class="px-3" v-if="!loading">
                <hr>
            </div>
            <div class="overflow-y-auto overflow-x-hidden px-3 pt-3 pb-3 scroll-pb-3 scroll-pt-3 focus:outline-none"
                v-if="!loading">
                <div class="flex flex-col">
                    <!-- ITEMS WHEN QUERY IS EMPTY -->
                    <template v-if="query.trim().length === 0">
                        <div @click="focusInput"
                            class="result-item relative cursor-pointer select-none rounded-md px-3 py-2.5 text-gray-400 hover:text-gray-700">
                            <div
                                class="relative flex cursor-pointer select-none items-start rounded-md gap-3 text-[15px] leading-[22px] transition-all duration-200 outline-none w-full justify-between">
                                <div class="h-[22px] flex items-center">
                                    <Icon name="heroicons:clipboard" class="h-5 w-5" aria-hidden="true" />
                                </div>
                                <div class="flex-1 truncate">
                                    <div class="truncate">Paste a URL to get insights</div>
                                </div>
                                <div class="shortcut ml-1.5 opacity-0 transition-all duration-200">
                                    <span
                                        class="bg-white flex items-center justify-center h-[22px] min-w-[22px] pr-1.5 pl-[7px] text-[13px] text-[#666666] rounded-[2px] shadow-[0_0_0_1px_rgba(0,0,0,0.05)]">Paste
                                        ↵</span>
                                </div>
                            </div>
                        </div>

                        <NuxtLink to="/discovery/search"
                            class="result-item relative cursor-pointer select-none rounded-md px-3 py-2.5 text-gray-400 hover:text-gray-700">
                            <div
                                class="relative flex cursor-pointer select-none items-start rounded-md gap-3 text-[15px] leading-[22px] transition-all duration-200 outline-none w-full justify-between">
                                <div class="h-[22px] flex items-center">
                                    <Icon name="heroicons:rectangle-stack" class="h-5 w-5" aria-hidden="true" />
                                </div>
                                <div class="flex-1 truncate">
                                    <div class="truncate">Discover reliable statistical sources</div>
                                </div>
                                <div class="shortcut ml-1.5 opacity-0 transition-all duration-200">
                                    <span
                                        class="bg-white flex items-center justify-center h-[22px] min-w-[22px] pr-1.5 pl-[7px] text-[13px] text-[#666666] rounded-[2px] shadow-[0_0_0_1px_rgba(0,0,0,0.05)]">
                                        Discovery
                                        <Icon name="heroicons:arrow-top-right-on-square"
                                            class="h-3 w-3 ml-1 text-gray-600 hover:text-gray-500" aria-hidden="true" />
                                    </span>
                                </div>
                            </div>
                        </NuxtLink>

                        <NuxtLink to="/knowledge/submit-data"
                            class="result-item relative cursor-pointer select-none rounded-md px-3 py-2.5 text-gray-400 hover:text-gray-700">
                            <div
                                class="relative flex cursor-pointer select-none items-start rounded-md gap-3 text-[15px] leading-[22px] transition-all duration-200 outline-none w-full justify-between">
                                <div class="h-[22px] flex items-center">
                                    <Icon name="heroicons:book-open" class="h-5 w-5" aria-hidden="true" />
                                </div>
                                <div class="flex-1 truncate">
                                    <div class="truncate">Connect your own sources</div>
                                </div>
                                <div class="shortcut ml-1.5 opacity-0 transition-all duration-200">
                                    <span
                                        class="bg-white flex items-center justify-center h-[22px] min-w-[22px] pr-1.5 pl-[7px] text-[13px] text-[#666666] rounded-[2px] shadow-[0_0_0_1px_rgba(0,0,0,0.05)]">
                                        My Sources
                                        <Icon name="heroicons:arrow-top-right-on-square"
                                            class="h-3 w-3 ml-1 text-gray-600 hover:text-gray-500" aria-hidden="true" />
                                    </span>
                                </div>
                            </div>
                        </NuxtLink>

                        <UPopover mode="click" v-if="!hasDocuments">
                            <div
                                class="result-item w-full relative cursor-pointer select-none rounded-md px-3 py-2.5 text-gray-400 hover:text-gray-700">
                                <div
                                    class="relative flex cursor-pointer select-none items-start rounded-md gap-3 text-[15px] leading-[22px] transition-all duration-200 outline-none w-full justify-between">
                                    <div class="h-[22px] flex items-center">
                                        <Icon name="heroicons:document-text" class="h-5 w-5" aria-hidden="true" />
                                    </div>
                                    <div class="flex-1 truncate">
                                        <div class="truncate">Create a new document</div>
                                    </div>
                                </div>
                            </div>

                            <template #panel>
                                <div class="w-[300px] pt-0 pb-0">
                                    <template v-if="!loadingCreateDocument">
                                        <div class="items-center gap-2.5 py-2 pl-3 flex text-gray-800 text-base">
                                            Choose a format
                                        </div>
                                        <div @click="createNewDocument(DocumentType.PAGE_A4)"
                                            class="items-center gap-2.5 hover:bg-gray-100 py-2 pl-2.5 cursor-pointer flex">
                                            <Icon name="heroicons:document-text" class="h-5 w-5 text-gray-800"></Icon>
                                            <p class="text-gray-800 text-base">Page (A4)</p>
                                            <span v-if="!enabledDocumentTypes.pageA4"
                                                class="ml-auto mr-2 text-xs bg-wobby-purple-500 text-white px-2 py-1 rounded">Upgrade</span>
                                        </div>
                                        <div @click="createNewDocument(DocumentType.SLIDES_16_9)"
                                            class="items-center gap-2.5 hover:bg-gray-100 py-2 pl-2.5 cursor-pointer flex">
                                            <Icon name="heroicons:presentation-chart-line"
                                                class="h-5 w-5 text-gray-800"></Icon>
                                            <p class="text-gray-800 text-base">Presentation (16:9)</p>
                                            <span v-if="!enabledDocumentTypes.slides16_9"
                                                class="ml-auto mr-2 text-xs bg-wobby-purple-500 text-white px-2 py-1 rounded">Upgrade</span>
                                        </div>
                                        <div @click="createNewDocument(DocumentType.SM_POST_SQUARE)"
                                            class="items-center gap-2.5 hover:bg-gray-100 py-2 pl-2.5 cursor-pointer flex">
                                            <Icon name="mdi:instagram" class="h-5 w-5 text-gray-800"></Icon>
                                            <p class="text-gray-800 text-base">Square</p>
                                            <span v-if="!enabledDocumentTypes.smPostSquare"
                                                class="ml-auto mr-2 text-xs bg-wobby-purple-500 text-white px-2 py-1 rounded">Upgrade</span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="items-center gap-2.5 py-2 pl-3 flex text-gray-800 text-base">
                                            Creating new doc...
                                        </div>
                                    </template>
                                    <div v-if="showUpgradeMessage" class="text-sm text-gray-600 p-2 bg-gray-100 mt-2">
                                        Upgrade your plan to access this document format. Redirecting you to the billing
                                        page...
                                    </div>
                                </div>
                            </template>
                        </UPopover>

                        <!-- SUGGESTIONS -->
                        <hr class="my-2">
                        <strong class="text-xs text-gray-500 font-fakt-medium pl-2.5 my-2"
                            v-if="randomSuggestions.length > 0">DISCOVERY
                            SUGGESTIONS</strong>
                        <div @click="submitSuggestion(suggestion.suggestion)" v-for="suggestion in randomSuggestions"
                            class="result-item relative cursor-pointer select-none rounded-md px-3 py-2.5 text-gray-400 hover:text-gray-700">
                            <div
                                class="relative flex cursor-pointer select-none items-start rounded-md gap-3 text-[15px] leading-[22px] transition-all duration-200 outline-none w-full justify-between">
                                <div class="h-[22px] flex items-center">
                                    <div class="text-[18px] rounded-lg">{{ suggestion.icon }}</div>
                                </div>
                                <div class="flex-1 truncate">
                                    <div class="truncate">{{ suggestion.suggestion }}</div>
                                </div>
                            </div>
                        </div>

                    </template>

                    <template v-else>
                        <!-- ITEMS WHEN QUERY IS NOT EMPTY (and query is NOT an url) -->
                        <template v-if="!queryIsUrl">
                            <div @click="submit"
                                class="result-item relative cursor-pointer select-none rounded-md px-3 py-2.5 text-gray-400 hover:text-gray-700">
                                <div
                                    class="relative flex cursor-pointer select-none items-start rounded-md gap-3 text-[15px] leading-[22px] transition-all duration-200 outline-none w-full justify-between">
                                    <div class="h-[22px] flex items-center">
                                        <Icon name="heroicons:magnifying-glass" class="h-5 w-5" aria-hidden="true" />
                                    </div>
                                    <div class="flex-1 truncate">
                                        <div class="truncate">Ask Wobby about <strong class="font-fakt-medium">"{{ query
                                                }}"</strong></div>
                                    </div>
                                </div>
                            </div>

                            <!-- SEARCH RESULTS -->
                            <template v-for="result in searchResults" :key="result.id">
                                <!-- Statistical Source (dataset) -->
                                <NuxtLink :to="`/knowledge/${result.id}`" v-if="result.type == 'DATASET'"
                                    class="result-item relative cursor-pointer select-none rounded-md px-3 py-2.5 text-gray-400 hover:text-gray-700">
                                    <div
                                        class="relative flex cursor-pointer select-none items-start rounded-md gap-3 text-[15px] leading-[22px] transition-all duration-200 outline-none w-full justify-between">
                                        <div class="h-[22px] flex items-center">
                                            <Icon name="heroicons:table-cells" class="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <div class="flex-1 truncate">
                                            <div class="truncate">{{ result.title }}</div>
                                        </div>
                                        <div class="shortcut ml-1.5 opacity-0 transition-all duration-200">
                                            <span
                                                class="flex items-center justify-center h-[22px] min-w-[22px] pr-1.5 pl-[7px] text-[13px] text-gray-300">{{
                                                    new Date(result.createdAt).toLocaleDateString() }}</span>
                                        </div>
                                    </div>
                                </NuxtLink>

                                <!-- Document -->
                                <NuxtLink :to="`/documents/${result.id}`" v-else-if="result.type == 'DOCUMENT'"
                                    class="result-item relative cursor-pointer select-none rounded-md px-3 py-2.5 text-gray-400 hover:text-gray-700">
                                    <div
                                        class="relative flex cursor-pointer select-none items-start rounded-md gap-3 text-[15px] leading-[22px] transition-all duration-200 outline-none w-full justify-between">
                                        <div class="h-[22px] flex items-center">
                                            <Icon name="heroicons:document-text" class="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <div class="flex-1 truncate">
                                            <div class="truncate">{{ result.title }}</div>
                                        </div>
                                        <div class="shortcut ml-1.5 opacity-0 transition-all duration-200">
                                            <span
                                                class="flex items-center justify-center h-[22px] min-w-[22px] pr-1.5 pl-[7px] text-[13px] text-gray-300">{{
                                                    new Date(result.createdAt).toLocaleDateString() }}</span>
                                        </div>
                                    </div>
                                    <div class="relative">
                                        <div class="text-[13px] pl-8 pt-2 line-clamp-3 transition-all duration-300">
                                            {{ result.intro }}
                                        </div>
                                    </div>
                                </NuxtLink>
                            </template>
                        </template>

                        <!-- ITEMS WHEN QUERY IS AN URL -->
                        <div v-else @click="createWebpage"
                            class="result-item relative cursor-pointer select-none rounded-md px-3 py-2.5 text-gray-400 hover:text-gray-700">
                            <div
                                class="relative flex cursor-pointer select-none items-start rounded-md gap-3 text-[15px] leading-[22px] transition-all duration-200 outline-none w-full justify-between">
                                <div class="h-[22px] flex items-center">
                                    <Icon name="heroicons:document-text" class="h-5 w-5" aria-hidden="true" />
                                </div>
                                <div class="flex-1 truncate">
                                    <div class="truncate">Add URL to knowledge</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <DialogsSavedPromptsDialog v-model:open="showSavedPromptsDialog" openedIn="home-search" @selectPrompt="query = $event?.split('\n')?.join(' ')" />
    </div>
</template>

<script setup lang="ts">
import { DocumentType, type HomeSearchResult, type ModalElement } from '~/types';
import { getOpenDataProviderInfo } from '@/utils';
import { getProviders } from '@/utils';
import type { ChatSearchResult, OpenDataFilterOptions } from '~/types';
import { useSchematicHq } from '~/composables/useSchematicHq';

const suggestions = [
    { icon: '🔍', suggestion: 'Unemployment rates in Europe' },
    { icon: '🏠', suggestion: 'Housing prices in Benelux' },
    { icon: '💨', suggestion: 'Gas emissions in Africa' },
    { icon: '🌐', suggestion: 'Global Internet Access Rates' },
    { icon: '📚', suggestion: 'EU Literacy Rate Comparison' },
    { icon: '💹', suggestion: 'US vs China GDP Growth' },
    { icon: '🏙️', suggestion: 'Global Urbanization Rates' },
    { icon: '👩‍💼', suggestion: 'Gender Pay Gap in EU' },
    { icon: '👥', suggestion: 'Population Growth in Scandinavia' },
    { icon: '💶', suggestion: 'Eurozone Inflation Trends' },
    { icon: '🎓', suggestion: 'Education Levels in Eastern EU' },
    { icon: '👶', suggestion: 'Birth Rates in EU' },
    { icon: '🚜', suggestion: 'Agricultural Subsidies in Belgium' },
    { icon: '⚡', suggestion: 'Energy Consumption in Asia' },
    { icon: '💧', suggestion: 'Water Scarcity Worldwide' },
    { icon: '🐟', suggestion: 'Fish Trade in Benelux' },
    { icon: '🌳', suggestion: 'Urban Green Spaces in Belgium' },
    { icon: '🚗', suggestion: 'Electric Car Ownership in EU' },
    { icon: '📊', suggestion: 'Consumer Price Index Changes in G20 countries' },
    { icon: '🛒', suggestion: 'Ecommerce Sales Trends in EU' },
    { icon: '📈', suggestion: 'Gas imports to Belgium' },
    { icon: '📉', suggestion: 'Stock Market Trends in Germany' },
    { icon: '🏦', suggestion: 'Banking Sector in France' },
    { icon: '📅', suggestion: 'Monthly Retail Sales in Spain' },
    { icon: '🏭', suggestion: 'Manufacturing Output in Italy' },
    { icon: '💼', suggestion: 'Employment Trends in Netherlands' },
    { icon: '📈', suggestion: 'Investment Opportunities in EU' },
    { icon: '📉', suggestion: 'Market Volatility in UK' },
    { icon: '📊', suggestion: 'Consumer Confidence in EU' },
    { icon: '🏢', suggestion: 'Real Estate Market in Germany' },
    { icon: '💡', suggestion: 'Innovation Index in Scandinavia' },
    { icon: '📱', suggestion: 'Social Media Usage Trends in APAC' },
    { icon: '🚀', suggestion: 'Startup Ecosystem in Silicon Valley' },
    { icon: '🏥', suggestion: 'Healthcare Expenditure in OECD Countries' },
    { icon: '🎮', suggestion: 'Gaming Industry Revenue in East Asia' },
    { icon: '🍔', suggestion: 'Fast Food Market Share in North America' },
    { icon: '📺', suggestion: 'Streaming Services Penetration in Europe' },
    { icon: '🛍️', suggestion: 'Luxury Goods Market in China' },
    { icon: '🏋️', suggestion: 'Fitness Industry Growth in Australia' },
    { icon: '🚢', suggestion: 'Global Shipping Routes and Volume' },
    { icon: '🧠', suggestion: 'AI Adoption in Fortune 500 Companies' },
    { icon: '🌿', suggestion: 'Organic Food Market Trends in EU' },
    { icon: '🚲', suggestion: 'Bike-sharing Programs in Major Cities' },
    { icon: '📰', suggestion: 'Print vs Digital Media Consumption' },
    { icon: '🏭', suggestion: 'Carbon Footprint of Tech Giants' },
    { icon: '💊', suggestion: 'Pharmaceutical R&D Spending by Country' },
    { icon: '🎓', suggestion: 'International Student Enrollment Trends' },
    { icon: '🏘️', suggestion: 'Airbnb Impact on Housing Markets' },
    { icon: '🚗', suggestion: 'Autonomous Vehicle Testing Locations' },
];

const props = defineProps({
    hasDocuments: {
        type: Boolean,
        default: false
    }
});

const router = useRouter();
const toast = useToast();

const datasetService = useDatasetService();
const { searchDocuments } = useDocumentService();
const { searchKnowledgeSources, createWebPage } = useKnowledgeService();

const strictSearchMode = ref(false);

const loadingCreateDocument = inject('loadingCreateDocument') as Ref<boolean>;
const createNewDocument = inject('createNewDocument') as (type: DocumentType) => void;

const query = ref('');
const autoCompleteString = ref('');
const randomSuggestions = useState('randomSuggestions', () =>
    suggestions.sort(() => Math.random() - 0.5).slice(0, 3)
);

const providersSelected = ref([] as string[]);

const loading = inject('loading') as Ref<boolean>;

const searchResults = ref([] as HomeSearchResult[]);

const showSavedPromptsDialog = ref(false);

const { getFeatureFlag } = useSchematicHq();

const enabledDocumentTypes = reactive({
    pageA4: false,
    slides16_9: false,
    smPostSquare: false,
});

const showUpgradeMessage = ref(false);

const submit = async () => {
    if (queryIsUrl.value) {
        createWebpage();
        return;
    }

    loading.value = true;

    if (query.value.trim().length === 0) {
        loading.value = false;
        submittedSuggestion.value = false;
        return;
    }

    if (query.value.length > 100) {
        loading.value = false;
        submittedSuggestion.value = false;
        toast.add({
            title: 'Message too long',
            description: 'Please keep your message under 100 characters.',
        });
        return;
    }

    let data = {} as ChatSearchResult;

    try {
        data = await datasetService.chatSearchDatasets({
            query: query.value,
            providers: providersSelected.value,
        } as OpenDataFilterOptions, 'publishedAt', strictSearchMode.value)
    } catch (e) {
        console.error(e);
        loading.value = false;
        submittedSuggestion.value = false;
        toast.add({
            title: 'Something went wrong',
            description: 'An error occurred while processing your request. Please try again later.',
        });
    }

    if (data.valid) {
        router.push({
            path: '/search',
            query: {
                result: data.id
            }
        });
    }

    loading.value = false;
    submittedSuggestion.value = false;

    document.dispatchEvent(new CustomEvent('closeCommandPalette'));
}

const submittedSuggestion = ref(false);
const submitSuggestion = (suggestion: string) => {
    // Remove special characters and trim
    suggestion = suggestion.replace(/[^\w\s]|_/g, "").trim();
    query.value = suggestion;
    submittedSuggestion.value = true;
    submit();
};
const isValidQuery = computed(() => {
    return query.value.trim().length > 1;
});

const queryIsUrl = computed(() => {
    return query.value.trim().match(/(http|https):\/\/[a-zA-Z0-9-.]+\.[a-zA-Z]{2,3}(\/\S*)?/);
});

const focusInput = () => {
    const input = document.querySelector('#searchQuery') as HTMLInputElement;
    input?.focus();
}

let debounceTimeout: any;
const loadingSearchResults = ref(false);
const onQueryInput = (e: Event) => {
    let event = e as InputEvent;
    resizeInput(event);

    clearTimeout(debounceTimeout);

    autoCompleteString.value = '';

    debounceTimeout = setTimeout(async () => {
        loadingSearchResults.value = true;
        searchResults.value = [];

        getSearchResults();
        getAutoComplete();
    }, 600);
};

const getSearchResults = async () => {
    try {
        if (query.value.trim()) {
            const [docRes, knowledgeRes] = await Promise.all([
                searchDocuments(query.value),
                searchKnowledgeSources(query.value)
            ]);
            searchResults.value = [...docRes.slice(0, 2), ...knowledgeRes.slice(0, 2)];
        }
    } catch (error) {
        console.error(error);
    }
    loadingSearchResults.value = false;
}

const getAutoComplete = () => {
    if (query.value.trim().split(' ').length > 1) {
        // TODO: Get autocomplete
    }
}

const resizeInput = (e: InputEvent) => {
    const target = e.target as HTMLInputElement;

    if (target.value.length === 0) {
        target.style.width = '100%';
        return;
    }

    let hiddenContent = document.getElementById('hiddenQuery') as HTMLInputElement;
    hiddenContent.textContent = target.value || " ";
    hiddenContent.style.font = window.getComputedStyle(target).font;
    hiddenContent.style.padding = window.getComputedStyle(target).padding;

    let spaces = target.value.match(/^ +| +$/g);
    let spaceCount = 0;
    if (spaces && spaces.length == 1) spaceCount = spaces[0].length;
    if (spaces && spaces.length == 2) spaceCount = spaces[0].length + spaces[1].length;
    let spaceWidth = 4.2;

    target.style.width = hiddenContent.offsetWidth + (spaceCount * spaceWidth) + 'px';
}

const providers = getProviders();
const filteredProviders = computed(() => {
    const providersAsArray = Object.keys(providers).map((key: string) => {
        const info = getOpenDataProviderInfo(key);
        return {
            id: key,
            name: info?.name
        }
    });

    return providersAsArray.sort((a, b) => a.name.localeCompare(b.name));
});

const createWebpage = async () => {
    try {
        loading.value = true
        const webpage = await createWebPage(query.value.trim(), 'user')
        router.push(`/knowledge/${webpage.id}`)
    } catch (error) {
        toast.add({
            title: 'Failed to create webpage',
            color: 'red'
        })
    } finally {
        loading.value = false
    }
}

onMounted(async () => {
    nextTick(() => {
        focusInput()
    });

    enabledDocumentTypes.pageA4 = await getFeatureFlag(FeatureFlag.DOCUMENT_A4_FORMAT);
    enabledDocumentTypes.slides16_9 = await getFeatureFlag(FeatureFlag.DOCUMENT_INFOGRAPHIC_FORMAT);
    enabledDocumentTypes.smPostSquare = await getFeatureFlag(FeatureFlag.DOCUMENT_INFOGRAPHIC_FORMAT);
});

const handleSearchEnter = () => {
    if (isValidQuery.value) {
        submit();
    }
}
</script>

<style scoped>
.result-item:hover:before {
    opacity: 1;
}

.result-item:hover .shortcut {
    opacity: 1;
}

.result-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #dfd4ff, #f2eeff);
    background: linear-gradient(45deg, #dbfff9, #eefffc);
    border-radius: 0.375rem;
    opacity: 0;
    transition: opacity 0.2s;
}
</style>
