import { formatDistance, formatDistanceToNowStrict } from 'date-fns'
import { INTERPRETED_TYPES, type OpenDataProviderInfo } from './types';
import providersJson from '@/data/providers.json';

const intervals: Record<string, number> = {};

export const formatDate = (date: Date | string): Date | string => {
    try {
        const distance = formatDistanceToNowStrict(new Date(date), { addSuffix: true });
        if (distance.includes('seconds') || distance.includes('second')) {
            return 'just now';
        }
        return distance;
    } catch (error) {
        return date;
    }
}

export function formatNumber(num: number): string {
    return new Intl.NumberFormat('en-US', { notation: 'compact' }).format(num);
}

export function formatTimeAgo(date: string | Date): string {
    const now = new Date();
    const past = new Date(date);
    const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);
    const diffInYears = Math.floor(diffInSeconds / (365 * 24 * 60 * 60));

    if (diffInYears > 0) {
        return `${diffInYears}y ago`;
    }

    const diffInMonths = Math.floor(diffInSeconds / (30 * 24 * 60 * 60));
    if (diffInMonths > 0) {
        return `${diffInMonths}mo ago`;
    }

    const diffInDays = Math.floor(diffInSeconds / (24 * 60 * 60));
    if (diffInDays > 0) {
        return `${diffInDays}d ago`;
    }

    return 'Today';
}

export const formatDateShort = (date: Date | string): Date | string => {
    try {
        const distance = formatDistanceToNowStrict(new Date(date), { addSuffix: false });

        const [value, unit] = distance.split(' ');

        let shortUnit;
        switch (unit) {
            case 'second':
            case 'seconds':
                return 'just now';  // or '0s' if you prefer
            case 'minute':
            case 'minutes':
                shortUnit = 'm';
                break;
            case 'hour':
            case 'hours':
                shortUnit = 'h';
                break;
            case 'day':
            case 'days':
                shortUnit = 'd';
                break;
            case 'week':
            case 'weeks':
                shortUnit = 'w';
                break;
            case 'month':
            case 'months':
                shortUnit = 'mo';
                break;
            case 'year':
            case 'years':
                shortUnit = 'y';
                break;
            default:
                return distance;
        }

        return `${value}${shortUnit}`;
    } catch (error) {
        return date;
    }
};

export async function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const isValidUrl = (url: string): boolean => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
}

export const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export function generateYears(fromYear: number, toYear: number): number[] {
    return Array.from({ length: fromYear - toYear + 1 }, (_, index) => fromYear - index);
}


export const getProviders = (): Record<string, OpenDataProviderInfo> => {
    return providersJson as unknown as Record<string, OpenDataProviderInfo>;
}

export function getOpenDataProviderInfo(name: string): OpenDataProviderInfo {
    return getProviders()[name] || { description: '', name: '', url: '', link: '', logo: '' };
}

export function capitalizeFirstLetter(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function tryConvertToDate(input: string): string | Date {
    const potentialDate = new Date(input);

    if (isNaN(potentialDate.getTime())) {
        // Invalid date
        return input;
    }
    return potentialDate;
}

interface ThemeCodeToTextMap {
    [themeCode: string]: string;
}

export const themeCodes: ThemeCodeToTextMap = {
    POP: "Population",
    MIG: "Migration",
    LAB: "Labour",
    EDU: "Education",
    HEA: "Health",
    INC: "Income",
    CONS: "Consumption",
    SOC: "Social Protection",
    HUM: "Human Settlements",
    HOU: "Housing",
    JUS: "Justice",
    CRM: "Crime",
    CUL: "Culture",
    POL: "Political Activities",
    COM: "Community Activities",
    TIM: "Time Use",
    MAC: "Macroeconomics",
    ECO: "Economic Accounts",
    BUS: "Business Statistics",
    AGR: "Agriculture",
    FOR: "Forestry",
    FIS: "Fisheries",
    ENE: "Energy",
    MIN: "Mining",
    MAN: "Manufacturing",
    CONT: "Construction",
    TRA: "Transport",
    TOU: "Tourism",
    BAN: "Banking",
    FIN: "Finance",
    GOV: "Government Finance",
    FISP: "Fiscal Policies",
    INT: "International Trade",
    PRI: "Prices",
    COS: "Costing",
    SCI: "Science",
    TEC: "Technology",
    INN: "Innovation",
    ENV: "Environment",
    REG: "Regional Statistics",
    SAD: "Small Area Data",
    LIV: "Living Conditions",
    POV: "Poverty",
    GEN: "Gender Issues",
    SPG: "Special Population Groups",
    GLO: "Globalisation",
    DEV: "Development Goals",
    SUS: "Sustainable Development",
    ENT: "Entrepreneurship",
    OTH: "Other"
};

export const themeCodeToText = (themeCode: string): string => {

    return themeCodes[themeCode] as string;
}


export const prettyTrialEndsIn = (trialEndsAt: any) => {
    if (new Date(trialEndsAt) < new Date()) return null;
    return formatDistance(new Date(trialEndsAt), new Date(), { addSuffix: true });
};

export const generateRandomString = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const separator = '-';
    let randomString = '';

    for (let i = 0; i < length; i++) {
        if (i === 4) {
            randomString += separator;
        }
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters[randomIndex];
    }

    return randomString;
}

// chartConfigModifier.ts

interface ChartConfig {
    title?: {
        show?: boolean;
        [key: string]: any;
    };
    subtitle?: {
        show?: boolean;
        [key: string]: any;
    };
    grid?: {
        top?: number | string;
        [key: string]: any;
    };
    series?: Array<{
        type?: string;
        [key: string]: any;
    }>;
    [key: string]: any;
}

export function modifyChartConfig(config: ChartConfig, topMargin: number = 10, chartType?: string): ChartConfig {
    // Create a deep copy of the config object
    const modifiedConfig: ChartConfig = JSON.parse(JSON.stringify(config));
    if (!modifiedConfig) return {};
  
    // Determine chart type if not provided
    if (!chartType && modifiedConfig.series && modifiedConfig.series.length > 0) {
        chartType = modifiedConfig.series[0].type;
    }
  
    // Apply chart-specific modifications
    switch (chartType) {
      case 'pie':
        // For pie charts, adjust the center
        if (modifiedConfig.series && modifiedConfig.series.length > 0) {
          modifiedConfig.series.forEach(series => {
            if (series.type === 'pie') {
              series.center = ['50%', '50%'];  // Center the pie chart
            }
          });
        }
        break;
      case 'treemap':
        // For treemap, adjust the top margin differently
        if (!modifiedConfig.grid) {
          modifiedConfig.grid = {};
        }
        modifiedConfig.grid.top = 0;  // Remove top margin for treemap
  
        // Adjust the series layout if needed
        if (modifiedConfig.series && modifiedConfig.series.length > 0) {
          modifiedConfig.series.forEach(series => {
            if (series.type === 'treemap') {
              // You might want to adjust these values based on your specific needs
              series.top = '10%';
              series.bottom = '10%';
              series.left = '10%';
              series.right = '10%';
            }
          });
        }
        break;
      default:
        // For other chart types, adjust the top margin
        if (!modifiedConfig.grid) {
          modifiedConfig.grid = {};
        }
        
        let totalTopMargin = 0;
        if (modifiedConfig.title?.show) {
          totalTopMargin += 15;
  
          if (modifiedConfig.legend?.top) {
              modifiedConfig.legend.top = "12%";
              totalTopMargin += 5;
          }
        }
        else if (modifiedConfig.legend?.top) {
          modifiedConfig.legend.top = "2%";
          totalTopMargin += 12;
        }
  
        modifiedConfig.grid.top = totalTopMargin > 0 ? totalTopMargin + "%" : topMargin;
        break;
    }

    modifiedConfig.title!.textStyle.fontSize = 14;
    modifiedConfig.title!.subtextStyle.fontSize = 12;

    return modifiedConfig;
}



export const getInterpretedTypeIconName = (interpretedType: string) => {
    const iconMap = {
        [INTERPRETED_TYPES.NUMERICAL]: 'heroicons:hashtag',
        [INTERPRETED_TYPES.TEXTUAL]: 'heroicons:document-text',
        [INTERPRETED_TYPES.EMAIL]: 'heroicons:at-symbol',
        [INTERPRETED_TYPES.URL]: 'heroicons:link',
        [INTERPRETED_TYPES.GEOSPATIAL]: 'heroicons:map',
        [INTERPRETED_TYPES.TEMPORAL]: 'heroicons:clock',
        [INTERPRETED_TYPES.CATEGORICAL]: 'heroicons:tag',
        [INTERPRETED_TYPES.OTHER]: 'heroicons:tag',
    };

    return iconMap[interpretedType as keyof typeof iconMap] || '';
};

export const getInterpretedTypeIconNameVerticalNav = (interpretedType: string) => {

    const iconMap: Record<string, string> = {
        [INTERPRETED_TYPES.NUMERICAL]: 'i-heroicons-hashtag',
        [INTERPRETED_TYPES.TEXTUAL]: 'i-heroicons-document-text',
        [INTERPRETED_TYPES.EMAIL]: 'i-heroicons-at-symbol',
        [INTERPRETED_TYPES.URL]: 'i-heroicons-link',
        [INTERPRETED_TYPES.GEOSPATIAL]: 'i-heroicons-map',
        [INTERPRETED_TYPES.TEMPORAL]: 'i-heroicons-clock',
        [INTERPRETED_TYPES.CATEGORICAL]: 'i-heroicons-tag',
        [INTERPRETED_TYPES.OTHER]: 'i-heroicons-tag',
    };

    return iconMap[interpretedType] || '';
};


export const downloadEChartImage = (chartInstance: any, title: string) => {
    // Hide the toolbox before downloading
    chartInstance.setOption({
        toolbox: { show: false }
    });

    // Generate the data URL for the chart image
    const dataURL = chartInstance.getDataURL({
        pixelRatio: 10,
    });

    // Create an anchor element and trigger the download
    const a = document.createElement('a');
    a.href = dataURL;
    a.download = `${title}.png`;
    a.click();
    // Remove the anchor element after download
    a.remove();

    // Restore the toolbox visibility after downloading
    chartInstance.setOption({
        toolbox: { show: true }
    });
}

export const continentCodeToName: Record<string, string> = {
    AF: "Africa",
    AS: "Asia",
    EU: "Europe",
    NA: "North America",
    OC: "Oceania",
    SA: "South America",
    AN: "Antarctica"
};

// split text on ':' and return first part
export const getInsightTaskTitle = (text: string) => {
    return text.split(':')[0];
}

export const transformChartLabel = (label: string, range: number) => {
    if (label.length > range) {
        return label.slice(0, range) + '...';
    }
    return label;
}

export function extractConfirmTags(text: string): Array<{ content: string; options: string[] }> {
    const regex = /<confirm options="([^"]+)">([^<]+)<\/confirm>/g;
    let matches;
    const results = [];

    while ((matches = regex.exec(text)) !== null) {
        results.push({
            content: matches[2].trim(),
            options: matches[1].split('|').map(option => option.trim()),
        });
    }

    return results;
}

export function generateWobbyColor(index: number) {
    const colorPalette = [
        '#7547FF',        // Central color
        '#8D67FF',        // Lighter central color
        '#5C38B7',        // Darker central color
        '#271E5F',        // Very dark central color
        '#00FFFF',        // Cyan (almost greenish)
        '#FF6FD8',        // A bit pinkish
        '#8B4513'         // Brown
    ];

    // Ensure the index is within the bounds of the color palette
    const colorIndex = index % colorPalette.length;

    return colorPalette[colorIndex];
}


export const europeanCountryCodeToName: Record<string, string> = {
    'AL': 'Albania',
    'AT': 'Austria',
    'BE': 'Belgium',
    'BG': 'Bulgaria',
    'HR': 'Croatia',
    'CY': 'Cyprus',
    'CZ': 'Czech Republic',
    'DK': 'Denmark',
    'EE': 'Estonia',
    'FI': 'Finland',
    'FR': 'France',
    'DE': 'Germany',
    'GR': 'Greece',
    'HU': 'Hungary',
    'IS': 'Iceland',
    'IE': 'Ireland',
    'IT': 'Italy',
    'LV': 'Latvia',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'MT': 'Malta',
    'NL': 'Netherlands',
    'NO': 'Norway',
    'PL': 'Poland',
    'PT': 'Portugal',
    'RO': 'Romania',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'ES': 'Spain',
    'SE': 'Sweden',
    'CH': 'Switzerland',
    'TR': 'Turkey',
    'GB': 'United Kingdom',
};



export const sortedUniqueValues = (values: string[]): any[] => {
    if (!values) return [];
    if (values.length > 0) {
        // Check type of the first value
        const firstValue = values[0];

        // Try parsing as a date
        const dateValue = Date.parse(firstValue);
        if (!isNaN(dateValue)) {
            return values.slice().sort((a, b) => Date.parse(a) - Date.parse(b));
        }

        // Try parsing as a number
        const numberValue = parseFloat(firstValue);
        if (!isNaN(numberValue)) {
            return values.slice().sort((a: string, b: string) => parseFloat(a) - parseFloat(b));
        }

        // Default to string sort
        return values.slice().sort();
    }
    return [];
};

export const getDateGroup = (date: Date) => {
    const dateGroups = [
        { title: "Today", value: "today", validate: (date: Date) => date.toDateString() === new Date().toDateString() },
        { title: "Yesterday", value: "yesterday", validate: (date: Date) => date.toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString() },
        { title: "2 Days ago", value: "2d", validate: (date: Date) => date > new Date(new Date().setDate(new Date().getDate() - 2)) },
        { title: "3 Days ago", value: "3d", validate: (date: Date) => date > new Date(new Date().setDate(new Date().getDate() - 3)) },
        { title: "1 Week ago", value: "1w", validate: (date: Date) => date > new Date(new Date().setDate(new Date().getDate() - 7)) },
        { title: "2 Weeks ago", value: "2w", validate: (date: Date) => date > new Date(new Date().setDate(new Date().getDate() - 14)) },
        { title: "3 Weeks ago", value: "3w", validate: (date: Date) => date > new Date(new Date().setDate(new Date().getDate() - 21)) },
        { title: "1 Month ago", value: "1m", validate: (date: Date) => date > new Date(new Date().setMonth(new Date().getMonth() - 1)) },
        { title: "2 Months ago", value: "2m", validate: (date: Date) => date > new Date(new Date().setMonth(new Date().getMonth() - 2)) },
        { title: "3 Months ago", value: "3m", validate: (date: Date) => date > new Date(new Date().setMonth(new Date().getMonth() - 3)) },
        { title: "6 Months ago", value: "6m", validate: (date: Date) => date > new Date(new Date().setMonth(new Date().getMonth() - 6)) },
        { title: "1 Year ago", value: "1y", validate: (date: Date) => date > new Date(new Date().setFullYear(new Date().getFullYear() - 1)) },
        { title: "More than 1 Year ago", value: "more", validate: (date: Date) => date < new Date(new Date().setFullYear(new Date().getFullYear() - 1)) }
    ];

    for (const group of dateGroups) {
        if (group.validate(date)) {
            return { title: group.title, value: group.value };
        }
    }
};

export const sortDateGroups = (dateGroups: Array<{ title: string; groupKey: string, chats: Chat[] }>) => {
    const sortedGroups = ["today", "yesterday", "2d", "3d", "1w", "2w", "3w", "1m", "2m", "3m", "6m", "1y", "more"];
    return dateGroups?.sort((a, b) => sortedGroups.indexOf(a.groupKey) - sortedGroups.indexOf(b.groupKey)) || [];
}

export const languageOptions = [
    {
        label: 'Afrikaans',
        value: 'af'
    },
    {
        label: 'Albanian',
        value: 'sq'
    },
    {
        label: 'Amharic',
        value: 'am'
    },
    {
        label: 'Arabic',
        value: 'ar'
    },
    {
        label: 'Armenian',
        value: 'hy'
    },
    {
        label: 'Azerbaijani',
        value: 'az'
    },
    {
        label: 'Basque',
        value: 'eu'
    },
    {
        label: 'Belarusian',
        value: 'be'
    },
    {
        label: 'Bengali',
        value: 'bn'
    },
    {
        label: 'Bosnian',
        value: 'bs'
    },
    {
        label: 'Bulgarian',
        value: 'bg'
    },
    {
        label: 'Catalan',
        value: 'ca'
    },
    {
        label: 'Cebuano',
        value: 'ceb'
    },
    {
        label: 'Chichewa',
        value: 'ny'
    },
    {
        label: 'Chinese (Simplified)',
        value: 'zh-CN'
    },
    {
        label: 'Chinese (Traditional)',
        value: 'zh-TW'
    },
    {
        label: 'Corsican',
        value: 'co'
    },
    {
        label: 'Croatian',
        value: 'hr'
    },
    {
        label: 'Czech',
        value: 'cs'
    },
    {
        label: 'Danish',
        value: 'da'
    },
    {
        label: 'Dutch',
        value: 'nl'
    },
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Esperanto',
        value: 'eo'
    },
    {
        label: 'Estonian',
        value: 'et'
    },
    {
        label: 'Filipino',
        value: 'tl'
    },
    {
        label: 'Finnish',
        value: 'fi'
    },
    {
        label: 'French',
        value: 'fr'
    },
    {
        label: 'Frisian',
        value: 'fy'
    },
    {
        label: 'Galician',
        value: 'gl'
    },
    {
        label: 'Georgian',
        value: 'ka'
    },
    {
        label: 'German',
        value: 'de'
    },
    {
        label: 'Greek',
        value: 'el'
    },
    {
        label: 'Gujarati',
        value: 'gu'
    },
    {
        label: 'Haitian Creole',
        value: 'ht'
    },
    {
        label: 'Hausa',
        value: 'ha'
    },
    {
        label: 'Hawaiian',
        value: 'haw'
    },
    {
        label: 'Hebrew',
        value: 'iw'
    },
    {
        label: 'Hindi',
        value: 'hi'
    },
    {
        label: 'Hmong',
        value: 'hmn'
    },
    {
        label: 'Hungarian',
        value: 'hu'
    },
    {
        label: 'Icelandic',
        value: 'is'
    },
    {
        label: 'Igbo',
        value: 'ig'
    },
    {
        label: 'Indonesian',
        value: 'id'
    },
    {
        label: 'Irish',
        value: 'ga'
    },
    {
        label: 'Italian',
        value: 'it'
    },
    {
        label: 'Japanese',
        value: 'ja'
    },
    {
        label: 'Javanese',
        value: 'jv'
    },
    {
        label: 'Kannada',
        value: 'kn'
    },
    {
        label: 'Kazakh',
        value: 'kk'
    },
    {
        label: 'Khmer',
        value: 'km'
    },
    {
        label: 'Korean',
        value: 'ko'
    },
    {
        label: 'Kurdish (Kurmanji)',
        value: 'ku'
    },
    {
        label: 'Kyrgyz',
        value: 'ky'
    },
    {
        label: 'Lao',
        value: 'lo'
    },
    {
        label: 'Latin',
        value: 'la'
    },
    {
        label: 'Latvian',
        value: 'lv'
    },
    {
        label: 'Lithuanian',
        value: 'lt'
    },
    {
        label: 'Luxembourgish',
        value: 'lb'
    },
    {
        label: 'Macedonian',
        value: 'mk'
    },
    {
        label: 'Malagasy',
        value: 'mg'
    },
    {
        label: 'Malay',
        value: 'ms'
    },
    {
        label: 'Malayalam',
        value: 'ml'
    },
    {
        label: 'Maltese',
        value: 'mt'
    },
    {
        label: 'Maori',
        value: 'mi'
    },
    {
        label: 'Marathi',
        value: 'mr'
    },
    {
        label: 'Mongolian',
        value: 'mn'
    },
    {
        label: 'Myanmar (Burmese)',
        value: 'my'
    },
    {
        label: 'Nepali',
        value: 'ne'
    },
    {
        label: 'Norwegian',
        value: 'no'
    },
    {
        label: 'Odia',
        value: 'or'
    },
    {
        label: 'Pashto',
        value: 'ps'
    },
    {
        label: 'Persian',
        value: 'fa'
    },
    {
        label: 'Polish',
        value: 'pl'
    },
    {
        label: 'Portuguese',
        value: 'pt'
    },
    {
        label: 'Punjabi',
        value: 'pa'
    },
    {
        label: 'Romanian',
        value: 'ro'
    },
    {
        label: 'Russian',
        value: 'ru'
    },
    {
        label: 'Samoan',
        value: 'sm'
    },
    {
        label: 'Scots Gaelic',
        value: 'gd'
    },
    {
        label: 'Serbian',
        value: 'sr'
    },
    {
        label: 'Sesotho',
        value: 'st'
    },
    {
        label: 'Shona',
        value: 'sn'
    },
    {
        label: 'Sindhi',
        value: 'sd'
    },
    {
        label: 'Sinhala',
        value: 'si'
    },
    {
        label: 'Slovak',
        value: 'sk'
    },
    {
        label: 'Slovenian',
        value: 'sl'
    },
    {
        label: 'Somali',
        value: 'so'
    },
    {
        label: 'Spanish',
        value: 'es'
    },
    {
        label: 'Sundanese',
        value: 'su'
    },
    {
        label: 'Swahili',
        value: 'sw'
    },
    {
        label: 'Swedish',
        value: 'sv'
    },
    {
        label: 'Tajik',
        value: 'tg'
    },
    {
        label: 'Tamil',
        value: 'ta'
    },
    {
        label: 'Telugu',
        value: 'te'
    },
    {
        label: 'Thai',
        value: 'th'
    },
    {
        label: 'Turkish',
        value: 'tr'
    },
    {
        label: 'Ukrainian',
        value: 'uk'
    },
    {
        label: 'Urdu',
        value: 'ur'
    },
    {
        label: 'Uyghur',
        value: 'ug'
    },
    {
        label: 'Uzbek',
        value: 'uz'
    },
    {
        label: 'Vietnamese',
        value: 'vi'
    },
    {
        label: 'Welsh',
        value: 'cy'
    },
    {
        label: 'Xhosa',
        value: 'xh'
    },
    {
        label: 'Yiddish',
        value: 'yi'
    },
    {
        label: 'Yoruba',
        value: 'yo'
    },
    {
        label: 'Zulu',
        value: 'zu'
    }
];

export const toneOptions = [
    { label: "Professional", value: "professional" },
    { label: "Casual", value: "casual" },
    { label: "Academic", value: "academic" },
    { label: "Humorous", value: "humorous" },
    { label: "Skeptical", value: "skeptical" },
    { label: "Journalistic", value: "journalistic" }
]
export const outputFormatOptions = [
    { label: "Summary", value: "summary" },
    { label: "News Article", value: "news_article" },
    { label: "Paper", value: "paper" },
    { label: "Blog", value: "blog" }
]