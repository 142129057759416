<template>
  <UModal v-model="open">
    <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <template #header>
        <div class="flex items-center justify-between">
          <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
            Save a new prompt
          </h3>
          <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1"
            @click="open = false" />
        </div>
      </template>

      <div class="flex flex-col gap-4">
        <UFormGroup label="Title (optional)" class="font-fakt-blond">
          <UInput v-model="promptData.title" />
        </UFormGroup>

        <UFormGroup label="Prompt" class="font-fakt-blond">
          <UTextarea v-model="promptData.prompt" :rows="4" />
        </UFormGroup>

        <div class="flex gap-2">
          <UFormGroup label="Available in" class="font-fakt-blond min-w-[180px]">
            <USelectMenu v-model="promptData.scope" :options="scopeOptions" value-attribute="value"
              option-attribute="label" multiple />
          </UFormGroup>

          <UFormGroup label="Visible for" class="font-fakt-blond min-w-[160px]">
            <div class="flex items-center space-x-2">
              <USelectMenu v-model="promptData.visibility" :options="['Only me', 'My organization']"
                :disabled="!sharePromptEnabled" />
              <UTooltip text="Not available for your plan" v-if="!sharePromptEnabled">
                <Icon name="heroicons:information-circle" class="h-5 w-5 text-gray-400" />
              </UTooltip>
            </div>
          </UFormGroup>
        </div>

        <UAlert v-if="error" :title="error" color="red" variant="soft" icon="i-heroicons-exclamation-triangle">
        </UAlert>
      </div>
      <template #footer>
        <div class="flex items-center justify-end gap-2">
          <UButton :disabled="loading" color="gray" @click="open = false" label="Cancel" />

          <UButton :loading="loading" color="wobby-purple" @click="submit" label="Create Prompt" />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
const identityStore = useIdentityStore();
const { createUserPrompt } = useIdentityService();
const { user } = storeToRefs(identityStore);

const open = defineModel('open', {
  default: false
});

const props = defineProps({
  message: {
    type: String,
    required: true
  },
  defaultScope: {
    type: String,
    required: false
  }
})

const emit = defineEmits(['created']);

const { data: sharePromptEnabled } = await useAsyncData(() => identityStore.checkFeatureFlag(FeatureFlag.SHARE_PROMPT))

const loading = ref(false)
const error = ref('')  // New error state

const promptData = ref({
  title: '',
  prompt: '',
  scope: props.defaultScope ? props.defaultScope.split(',') : ['chat', 'research-assistant', 'home-search'],
  visibility: 'Only me'
})

const scopeOptions = ref([
  { label: 'Chat', value: 'chat' },
  { label: 'Research assistant', value: 'research-assistant' },
  { label: 'Home search', value: 'home-search' },
  { label: 'Document context', value: 'document-context' }
])

watch(() => open.value, (value) => {
  if (value) {
    promptData.value.prompt = props.message;
    promptData.value.scope = props.defaultScope ? props.defaultScope.split(',') : ['chat', 'research-assistant', 'home-search'];
    loadPromptName();
  }
})

const loadPromptName = () => {

}

const submit = async () => {
  loading.value = true;
  error.value = '';

  const prompt = {
    title: promptData.value.title,
    prompt: promptData.value.prompt,
    scope: promptData.value.scope,
    sharedWithOrganization: promptData.value.visibility === 'My organization'
  }

  try {
    let res = await createUserPrompt(user.value!.id, prompt);
    if (res.id && res.prompt) {
      emit('created');
      open.value = false;
      promptData.value = {
        title: '',
        prompt: '',
        scope: ['chat', 'research-assistant', 'home-search'],
        visibility: 'Only me'
      }
    } else {
      throw new Error('Invalid response from server');
    }
  } catch (err) {
    error.value = 'Failed to create prompt. Please try again.';
  } finally {
    loading.value = false;
  }
}
</script>