import { useIdentityService } from '@/composables/useIdentityService'
import type { User, UserPreference } from '@/types'

import { defineStore } from 'pinia'

export enum FeatureFlag {
  STATISTICAL_SOURCES = 'access-to-public-live-statistical-sources',
  SHARE_PROMPT = 'share-prompt',
  NUMBER_OF_CHARTS = 'number-of-charts',
  DOCUMENT_A4_FORMAT = 'document-a4-format',
  DOCUMENT_INFOGRAPHIC_FORMAT = 'document-infographic-format',
  SQL_CONNECTION = 'sql-connection',
  OUTPUT_TABLE_AS_SOURCE = 'output-table-as-source',
  PDF_UPLOAD = 'pdf-upload',
}

export const useIdentityStore = defineStore('identity', {
  state: () => {
    return {
      authenticationLoading: true,
      authenticated: false,
      jwtFetched: false,
      user: null as User | null,
      preferences: null as UserPreference | null,
      hasSubscription: false,
      organizationFonts: [] as string[],
    }
  },
  actions: {
    async initSchematic() {
      if (process.client && this.user) {
        try {
          const schematic = useSchematicHq();
          await schematic.identify(this.user)
        } catch (e) {
          console.log(e)
        }
      }
    },
    async loadOrganiztaionFonts() {
      if (process.client && this.user) {
        const identityService = useIdentityService();
        try {
          const organizationFonts = await identityService.getOrganizationFonts(this.user!.organization!.id);
          for (const font of organizationFonts) {
            const loadedFont = new FontFace(font.name, `url(/api/identity/organizations/${this.user.organization.id}/fonts/${font.id}/font.woff2)`)
            document.fonts.add(loadedFont)
            this.organizationFonts.push(font.name)
            await loadedFont.load()
            console.log(`Loaded font ${font.name}`)
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    async initIdentity(): Promise<boolean> {
      const identityService = useIdentityService();
      const status = await identityService.getAuthenticationStatus();

      if (status.authenticated) {
        this.user = status.user;
        this.authenticated = true;
        this.preferences = await identityService.getUserPreferences(status.user!.id);
        const subscription = await identityService.checkSubscriptionStatus();
        this.hasSubscription = subscription.hasSubscription;
        await this.initSchematic();
        await this.loadOrganiztaionFonts();
      }

      this.authenticationLoading = false;
      return status.authenticated;
    },
    async ensureJwtFetched() {
      if (this.jwtFetched) return;
      const identityService = useIdentityService();
      await identityService.getAuthenticationStatus();
      this.jwtFetched = true;
    },
    async login(email: string, password: string): Promise<void> {
      const identityService = useIdentityService();
      await identityService.loginWithLocalAccount({ email, password, keepSignedIn: true });
      await this.initIdentity();
    },
    async updateUser(user: Partial<User>) {
      const identityService = useIdentityService();
      const updated = await identityService.updateUser(user);
      this.user = updated;
    },
    async checkFeatureFlag(flag: FeatureFlag): Promise<boolean> {
      try {
        const schematic = useSchematicHq();
        return schematic.getFeatureFlag(flag);
      } catch (e) {
        console.log(e)
        return true;
      }
    },
  },
  getters: {
    hasFeature: (state) => (feature: string) => {

      if (!state.user) return false;
      if (!state.user.organization) return false;
      return state.user.organization.tier.features.map(e => e.name).includes(feature) || false;
    },
    isUserInActiveTrial: (state) => {
      if (!state.user) {
        return false;
      }

      if (state.hasSubscription) {
        return false;
      }

      const now = new Date().getTime();
      const trialEndTime = new Date(state.user.trialEndsAt!).getTime();
      const isTrialTier = state.user.organization?.tier.name === 'TRIAL';
      const isTrialActive = trialEndTime >= now;

      return isTrialTier && isTrialActive;
    },
  },
})
