import axios from 'axios';
import type { User, AuthenticationStatus, UserPreference, SavedPrompt, OrganizationFont } from '@/types';


export function useIdentityService() {
    const config = useRuntimeConfig();
    const api = axios.create({
        baseURL: `${config.public.baseUrl}/api/identity`,
        headers: useRequestHeaders(['cookie'])
    })

    async function getAuthenticationStatus() {
        const { data } = await api.get<AuthenticationStatus>('/status');

        return data;
    }

    async function getUser(userId: string) {
        const { data } = await api.get<User>(`/users/${userId}`)

        return data
    }

    async function getOrganizationUsers(organizationId: string) {
        const { data } = await api.get<User[]>(`/organizations/${organizationId}/users`)

        return data
    }

    async function registerWithLocalAccount(details: any): Promise<User | any> {
        const { data } = await api.post<User>('/register', details);

        return data;
    }

    async function loginWithLocalAccount(details: { email: string, password: string, keepSignedIn: boolean }): Promise<User> {
        const { data } = await api.post<{ id: string, user: User }>('/login', details);


        return data.user;
    }

    async function signout(): Promise<void> {
        await api.post<User>('/signout');
    }

    async function updateUser(user: Partial<User>): Promise<User> {
        const { data } = await api.patch<User>(`/users/${user.id}`, user);

        return data;
    }

    async function deleteUser(userId: string, password: string | null): Promise<void> {
        await api.delete(`/users/${userId}`, {
            data: {
                password
            }
        });
    }

    async function updatePassword(userId: string, currentPassword: string, newPassword: string): Promise<void> {
        await api.put(`/users/${userId}/password`, {
            currentPassword,
            newPassword
        });
    }

    async function requestEmailVerification(email: string): Promise<{ status: string }> {
        const { data } = await api.post<{ status: string }>('/verify-email', {
            email
        });
        return data;
    }

    async function resendVerificationEmail(email: string) {
        await api.post('verify-email/resend', {
            email
        });
    }

    async function submitEmailVerificationToken(email: string, token: string): Promise<boolean> {
        const { data } = await api.post<{ status: string }>('/verify-email', {
            email,
            token
        });

        if (data.status === 'ok') {
            return true;
        }

        return false;
    }

    async function submitOnboardingQuestions(userId: string, questions: { question: string; answer: string; tag: string }[]): Promise<void> {
        await api.post(`/users/${userId}/onboarding`, questions);
    }

    async function requestPasswordReset(email: string): Promise<void> {
        await api.post('/forgot-password', {
            email
        });
    }

    async function resetPassword(token: string, newPassword: string): Promise<void> {
        await api.post('/reset-password', {
            token,
            password: newPassword
        });
    }

    async function getTiers() {
        const { data } = await api.get('/tiers')
        return data
    }

    async function getCustomerPortalLink() {
        const { data } = await api.get<string>(`/integrations/stripe/customer-portal`)

        return data
    }

    async function getUsers(search: Partial<User>): Promise<User[]> {
        const { data } = await api.get<User[]>('/users', {
            params: {
                ...search
            }
        })

        return data
    }

    async function getIntegration(type: string) {
        const { data } = await api.get<{ exists: boolean, data: any }>(`/integrations/${type}`)

        return data
    }

    async function deleteIntegration(type: string) {
        await api.delete(`/integrations/${type}`)
    }

    async function getUserPreferences(userId: string) {
        const { data } = await api.get<UserPreference>(`/users/${userId}/preferences`)

        return data
    }

    async function updateUserPreferences(userId: string, preferences: any) {
        const { data } = await api.patch<UserPreference>(`/users/${userId}/preferences`, preferences)

        return data
    }

    async function inviteUser(organizationId: string, email: string): Promise<any> {
        const { data } = await api.post(`/organizations/${organizationId}/invite`, { email });
        return data;
    }

    async function acceptInvite(token: string): Promise<any> {
        const { data } = await api.post(`/invitations/${token}/accept`);
        return data;
    }

    async function getUserPrompts(userId: string) {
        const { data } = await api.get<SavedPrompt[]>(`/users/${userId}/prompts`)
        return data
    }

    async function getOrganizationPrompts(organizationId: string) {
        const { data } = await api.get<SavedPrompt[]>(`/organizations/${organizationId}/prompts`)
        return data
    }

    async function createUserPrompt(userId: string, prompt: any) {
        const { data } = await api.post<SavedPrompt>(`/users/${userId}/prompts`, prompt)
        return data
    }

    async function updateUserPrompt(userId: string, promptId: string, prompt: any) {
        const { data } = await api.patch<SavedPrompt>(`/users/${userId}/prompts/${promptId}`, prompt)
        return data
    }

    async function deleteUserPrompt(userId: string, promptId: string) {
        const { data } = await api.delete(`/users/${userId}/prompts/${promptId}`)
        return data
    }

    async function listPlans() {
        const { data } = await api.get('/integrations/schematic/plans')
        return data
    }

    async function checkout(priceId: string) {
        const { data } = await api.post<string>('/integrations/stripe/checkout-subscription', {
            priceId
        })
        return data
    }

    async function checkSubscriptionStatus() {
        const { data } = await api.get<{ hasSubscription: boolean, subscription: any, hadSubscription: boolean }>('/integrations/stripe/check-subscription')
        return data
    }


    async function cancelSubscription() {
        await api.post('/integrations/stripe/cancel-subscription')
    }

    async function resumeSubscription() {
        await api.post('/integrations/stripe/resume-subscription')
    }

    async function getFeatureUsage() {
        const { data } = await api.get('/integrations/schematic/usage');
        return data
    }

    async function ping() {
        await api.get('/ping')
    }

    async function getSchematicPlan() {
        const { data } = await api.get('/integrations/schematic/check-subscription')
        return data
    }

    async function createOrganizationFont(organizationId: string, file: File,) {
        const formData = new FormData();
        formData.append('font', file);

        const { data } = await api.post(`/organizations/${organizationId}/fonts`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        return data
    }

    async function getOrganizationFonts(organizationId: string) {
        const { data } = await api.get<OrganizationFont[]>(`/organizations/${organizationId}/fonts`)

        return data
    }

    async function downloadFont(organizationId: string, fontId: string) {
        const { data } = await api.get(`/organizations/${organizationId}/fonts/${fontId}/font.woff2`, {
            responseType: 'blob'
        })

        return data
    }

    return {
        getOrganizationFonts,
        downloadFont,
        createOrganizationFont,
        getSchematicPlan,
        ping,
        getUserPreferences,
        acceptInvite,
        updateUserPreferences,
        deleteIntegration,
        getAuthenticationStatus,
        registerWithLocalAccount,
        loginWithLocalAccount,
        signout,
        updateUser,
        deleteUser,
        updatePassword,
        requestEmailVerification,
        submitEmailVerificationToken,
        submitOnboardingQuestions,
        requestPasswordReset,
        resetPassword,
        getTiers,
        resendVerificationEmail,
        getCustomerPortalLink,
        getUsers,
        getIntegration,
        getOrganizationUsers,
        getUser,
        inviteUser,
        getUserPrompts,
        getOrganizationPrompts,
        createUserPrompt,
        updateUserPrompt,
        deleteUserPrompt,
        listPlans,
        checkout,
        checkSubscriptionStatus,
        cancelSubscription,
        resumeSubscription,
        getFeatureUsage
    }
}